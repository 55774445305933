import { ref } from 'vue';
import axios from '@/axios';
import store from '@/store'


export default function useContactUs() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive

    const sendFormData = async (data) => {
        try {
            let response = await axios.post('/api/contact-us', data);
            msgs.value = response.data.message;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);

        }
    }

    return {
        sendFormData,
        msgs,
        errors,
    }
}