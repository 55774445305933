<template>
	<div class="page-content">
		<!-- inner page banner -->
		<div class="dlab-bnr-inr overlay-black-dark banner-content bnr">
			<div class="container">
				<div class="dlab-bnr-inr-entry">
					<h1 class="text-white">Contact Us</h1>
					<!-- Breadcrumb row -->
					<div class="breadcrumb-row">
						<ul class="list-inline">
							<li>
								<router-link :to="{name: 'Home'}">Home</router-link>
							</li>
							<li>Contact Us</li>
						</ul>
					</div>
					<!-- Breadcrumb row END -->
				</div>
			</div>
		</div>

		<!-- contact area -->
		<div class="section-full content-inner-2 contact-style-3">
			<div class="container">
				<div class="row m-lr0 contact-form-box">
					<div class="col-lg-4 col-md-5 p-lr0">
						<img src="~@/assets/imgs/contact_side_img.jpg" style="height:100%">
					</div>
					<div class="col-lg-8 col-md-7 p-lr0">
						<div class="contact-form bg-white">
							<div class="section-head text-left">
								<h3 class="title">
									Get In Touch <br>
									<small style="font-style: italic; font-size: 40%">Welcome to our School, we are glad to have you around!</small>
								</h3>
								<div class="dlab-separator bg-primary"></div>
							</div>
							<!-- FeedBack -->
							<feedback-messages :msgs="msgs" :errors="errors" />
							<!-- /FeedBack -->
							<form @submit.prevent="sendData()">
								<input type="hidden" value="Contact" name="dzToDo">
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<div class="input-group">
												<input v-model="contactUsFormData.name" type="text" required=""
													class="form-control" placeholder="Your Name">
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<div class="input-group">
												<input v-model="contactUsFormData.email" type="email"
													class="form-control" required="" placeholder="Your Email">
											</div>
										</div>
									</div>
									<div class="col-lg-12">
										<div class="form-group">
											<div class="input-group">
												<input v-model="contactUsFormData.phone" type="text" required=""
													class="form-control" placeholder="Phone">
											</div>
										</div>
									</div>
									<div class="col-lg-12">
										<div class="form-group">
											<div class="input-group">
												<textarea v-model="contactUsFormData.message" rows="4"
													class="form-control" required=""
													placeholder="Your Message..."></textarea>
											</div>
										</div>
									</div>
									<div class="col-md-12">
										<button :disabled="disableBtn" name="submit" type="submit"
											class="site-button" value="Submit" onclick="this.blur();"> Send </button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FeedbackMessages from '@/components/FeedbackMessages.vue';
	import useContactUs from "@/composables/contact_us";
	import {
		reactive,
		ref
	} from 'vue';

	export default {
		components: {
			FeedbackMessages
		},

		setup() {

			const disableBtn = ref(false);

			const {
				sendFormData,
				msgs,
				errors,
			} = useContactUs();

			const contactUsFormData = reactive({
				'name': '',
				'email': '',
				'phone': '',
				'message': '',
			});

			const sendData = async () => {
				errors.value = [];
				disableBtn.value = true;
				await sendFormData({
					...contactUsFormData
				}).then(() => {
					disableBtn.value = false;
					resetFormData();
				});

			}

			//reset the sign in form inputs
			const resetFormData = () => {
				contactUsFormData.name = '';
				contactUsFormData.email = '';
				contactUsFormData.phone = '';
				contactUsFormData.message = '';
			}

			return {
				sendData,
				contactUsFormData,
				msgs,
				errors,
				disableBtn,
			}
		}
	}
</script>

<style scoped>
	.bnr {
		background-image: url("~@/assets/imgs/contact_us.jpg") !important;
	}
</style>